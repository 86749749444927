import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import './header.css'

const Header = ({ siteTitle }) => (
  <header
    style={{
    }}
  >
    <h2 style={{ margin: 0 }}>
        <Link
          to="/"
          className="header"
        >
          {siteTitle}
        </Link>
        
      </h2>
      <a href="https://www.fajarsiddiq.com/">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.2063 30.881V25.3665H21.7706V30.6869C21.7706 30.6869 21.8315 33.954 18.9155 36.0655C18.9155 36.0655 14.3158 39.209 10.2337 35.483C10.2337 35.483 8.15815 33.4358 8.00011 31.2111V10.0268C8.00011 10.0268 7.86936 1.59961 16.4294 1.59961H27.5002C27.5002 1.59961 28.9846 1.62701 30.7437 2.90058C30.7437 2.90058 32.9495 7.17675 29.0345 8.22094C29.0345 8.22094 28.8832 6.42289 27.1117 6.24037H16.8956C16.8956 6.24037 13.1308 6.40252 12.5449 11.2889V30.4539C12.5449 30.4539 12.5495 32.9614 14.9145 32.9005C14.9145 32.9005 17.1643 33.1148 17.2063 30.881Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.0297 8.2086C29.0297 8.2086 28.8796 7.35401 28.4805 6.92396C28.4805 6.92396 31.156 6.2131 30.7054 2.86133C30.7054 2.86133 32.8452 7.59719 29.0297 8.2086Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.1927 30.881C17.1927 30.881 19.1306 27.2424 21.7706 28.4344V26.7061H19.4781C19.4781 26.7061 16.8891 26.8754 17.1927 30.881Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.4923 8.36461L27.5186 8.35645C27.5186 8.35645 32.374 8.92567 30.6844 2.86133C30.6844 2.86133 33.6631 4.67928 33.5978 8.36461C33.5978 8.36461 33.4962 12.9514 28.6682 12.9514H20.6393C20.6393 12.9514 19.4923 12.9514 19.4923 14.0981C19.4923 14.0981 19.4923 15.2448 20.6393 15.2448H28.6682C28.6682 15.2448 33.0555 15.9977 33.5784 20.337C33.5784 20.337 34.1368 25.5649 28.6682 26.7117H19.4923C19.4923 26.7117 17.1983 26.9838 17.1983 30.8612C17.1983 30.8612 14.1005 29.282 14.6416 25.9874C14.6416 25.9874 15.1722 22.1249 19.4923 22.1249H27.5211C27.5211 22.1249 28.6682 22.1249 28.6682 20.9781C28.6682 20.9781 28.6682 19.8315 27.5211 19.8315H19.4923C19.4923 19.8315 14.6601 18.6849 14.6416 14.0982C14.6416 14.0982 14.5365 9.08194 19.4923 8.36461Z" fill="white"/>
</svg>
      </a>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
